@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.content {
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(243, 244, 249);
}

body {
  font-family: "Montserrat", sans-serif;
}
